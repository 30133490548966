.PaymentPointsCard {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 14px;
  background: var(--systemWhite);
  border: 1px solid var(--systemBlack25);
  border-radius: 8px;
  color: var(--systemBlack);

  .error {
    color: var(--systemRed);
  }

  .cardWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 110%;

    .cardTitle {
      // margin-bottom: 10px;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 110%;
        margin: 0;
      }
      
      button {
        display: flex;
        align-items: center;
      }

    }

    .subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 120%;
      color: var(--systemBlack50);
      margin: 2px 0 8px;
    }

    .cardMain {
      display: flex;
      align-items: center;
      justify-content: space-between;

        span {
          color: var(--systemOrange);
        }
    }
  }

  .cardBtn {
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    color: var(--systemOrange);

    div svg {
      height: 13px;
    }
  }
}

.PaymentPointsCard.disabled {
  background-color: var(--systemBlack10);
  color: var(--systemBlack50);

  .cardMain span {
    color: var(--systemBlack50);
  }

  .cardBtn:disabled {
    color: var(--systemBlack50);
    cursor: default;
  }
}

.PaymentPointsCardWrap {
  min-height: 144px;
  display: flex;
  align-items: flex-start;
  margin: 16px 0;
}

@media (max-width: 380px) {
  .cardMain div {
    font-size: 13px;
  }
}