.map {
  display: flex;
  height: 400px;
  // flex-direction: column;
  // overflow: hidden;
  width: 100%;
}

.mapContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: var(--systemWhite);
  border: 1px solid rgba(50, 50, 50, 0.25);
  border-radius: 12px;
  margin-top: 12px;
  max-width: 1024px;
}

.selectedPlacesContainer {
  height: 79px;
  background-color: var(--systemWhite);
  border: 1px solid rgba(189, 189, 189, 0.25);
  box-sizing: border-box;
  display: flex;
  align-content: center;
  padding-top: 20px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-left: 19px;
  z-index: 4;
}

.addToCardButtonContainer {
  align-self: center;
  margin-left: auto;
}

.placeCardWrapper {
  position: relative;
  bottom: 59px;
  height: auto;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, .2));
  transform: scale(1);
  transition: .3s linear;

  &:last-child, &:hover {
    overflow: visible;
    z-index: 6 !important;
    filter: drop-shadow(0 2px 8px rgb(0, 0, 0, .3));
    transform: scale(1.02);
  }
  &:not(:first-child) {
    margin-left: -88px;
  }
}

.placeCard {
  background-color: var(--systemWhite);
  width: 200px;
  min-width: 200px;
  min-height: 100px;
  border-radius: 8px;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
}
.placeCardTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--systemBlack50);
  margin-right: 4px;
}
.placeCardNumbers {
  max-width: 144px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.placeCardNumberBlock {
  margin-right: 6px;
}
.placeCardPlaceNumber {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--clubPrimary);
}
.placeCardNumber {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--systemBlack);
}
.placeCardPrice {
  font-weight: 700;
  font-size: 18px;
  line-height: 110%;
  letter-spacing: normal;
  color: var(--systemBlack);

  margin-top: auto;
  margin-bottom: 0;
}

.placeCardRemoveButton {
  width: 34px;
  height: 34px;
  position: absolute;
  right: 10px;
  top: 33px;
  background: #bdbdbd;
  border-radius: 35px;
  outline: none;
  border: 0;
  transition: .3s linear;

  svg {
    width: 24px;
    height: 24px;
    margin-top: 2px;
  }

  &:hover {
    background-color: var(--systemRed);
    cursor: pointer;
    box-shadow: none;
  }
}

.placeCardRows {
  display: flex;
}


@media (max-width: 975px) {
  .selectedPlacesContainer {
    height: auto;
    flex-direction: column;
  }
  .placesWrapperMobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  .placesBlockMobile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .addToCardButtonContainer {
    width: 100%;

    & button {
      width: 100%;
    }
  }
  .placeCardRemoveButton {
    width: 14px;
    height: 14px;
    position: relative;
    right: 0;
    top: 0;
    display: inline-block;
    border-radius: 50%;
    margin-left: 12px;
    padding: 0;

    svg {
      width: 10px;
      height: 9px;
      margin-top: 0;
      margin-bottom: 2px;
    }
    span {
      margin: 0 !important;
    }
  }
  .placeCardTitle {
    font-size: 14px;
    color: var(--systemBlack);
    margin-right: 0;
  }
  .placeCardPrice {
    font-size: 16px;
    margin-top: 0;
    margin-left: auto;
  }
}

@media screen and (min-width: 761px) and (max-height: 1080px) {
  .selectedPlacesContainer {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: calc(100% - 32px);
    max-width: inherit;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
  }
}

@media (max-width: 760px) {
  .selectedPlacesContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
  }
}
